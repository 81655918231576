import React from "react";
import "./contact.css";

export default function ContactContainer() {
  return (
    <div className="main-contact">
      <div>
        <h1 className="contact-title">Kontakt</h1>
        <p className="contact-paragraph">
          Om du har spørsmål om tjenestene jeg kan tilby, send meg en e-post på{" "}
          <b>hallo@olaola.no</b>. <br />
          <br /> Eller send en sms eller ring på <b>+47 48035085 </b>.
        </p>
      </div>
      <img
        src="papirfly.jpg"
        alt="Tegning av mann som kaster papirfly."
        width="100%"
      />
    </div>
  );
}
