import React, { useEffect, useState } from "react";
import Preview from "./Preview";
import FadeInSection from "./FadeInSection";
import { Link } from "react-router-dom";
import "./film&sound.css";
import "./../../index.css";

import client from "./../../client";
import groq from "groq";

const QUERY = groq`*[_type == "filmAndSound"] {description, title, _id, "slug": slug.current, video, company, "thumbnail": thumbnail.asset->url, "audio": *[_type == "audio" && references(^._id)] {title, description, "image": image.asset->url, "audiofile": audio.asset->url}}`;

export default function FilmContainer() {
  const [data, setData] = useState([]);

  useEffect(() => {
    client.fetch(QUERY).then(res => {
      setData(res);
    });
  }, []);

  return (
    <div className="grid">
      {data.map((el, index) => {
        return (
          <FadeInSection key={index}>
            <Link
              to={{
                pathname: `/film-og-lyd/` + el.slug,
                state: {
                  post: el
                }
              }}
              style={{ textDecoration: "none", color: "white" }}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Preview data={el} />
            </Link>
          </FadeInSection>
        );
      })}
    </div>
  );
}
