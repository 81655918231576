import React from "react";
import { NavLink } from "react-router-dom";
import HamburgerMenu from "./../layout/HamburgerMenu";
import "./layout.css";

export default function Header() {


  return (
    <div className="header">
      <div className="header-content">
        <NavLink
          exact
          to="/"
          style={{
            textDecoration: "none",
            color: "white",
            margin: "0 8vw"
          }}
          activeStyle={{
            visibility: "hidden"
          }}
        >
          <h1 className="ola">Ola Hegdal</h1>
        </NavLink>
        <div className="menu">
          <NavLink
            to="/anmeldelser"
            style={
              ({
                marginRight: "4vw"
              },
              defaultStyle)
            }
            activeStyle={activeStyle}
          >
            <h3 className="menu-choice">Anmeldelser</h3>
          </NavLink>
          <NavLink
            to="/foredrag"
            style={defaultStyle}
            activeStyle={activeStyle}
          >
            <h3 className="menu-choice">Foredrag</h3>
          </NavLink>
          <NavLink
            to="/film-og-lyd"
            style={defaultStyle}
            activeStyle={activeStyle}
          >
            <h3 className="menu-choice">Film og lyd</h3>
          </NavLink>
          <NavLink
            to={{
              pathname: `/tegneserier`,
              state: { type: "cartoonCategory" }
            }}
            style={defaultStyle}
            activeStyle={activeStyle}
          >
            <h3 className="menu-choice">Tegneserier</h3>
          </NavLink>
          <NavLink
            to={{
              pathname: `/illustrasjoner`,
              state: { type: "illustrationCategory" }
            }}
            style={defaultStyle}
            activeStyle={activeStyle}
          >
            <h3 className="menu-choice">Illustrasjoner</h3>
          </NavLink>
          <NavLink
            to="/kontakt"
            style={
              ({
                marginLeft: "6vw"
              },
              defaultStyle)
            }
          >
            <div className="contact-button">
              <h3 className="menu-choice cont">Kontakt</h3>
            </div>
          </NavLink>
        </div>
      </div>
      <div className="hamburger-container">
      <HamburgerMenu
        pageWrapId={"page-wrap"}
        outerContainerId={"outer-container"}
      />
      </div>
    </div>
  );
}

const defaultStyle = {
  fontSize: "0.8vw",
  textTransform: "uppercase",
  color: "#dfe2e3",
  textDecoration: "none",
  margin: "0 1.5vw",
  paddingBottom: "3px"
};

const activeStyle = {
  borderBottom: "3px solid #bbd2c9",
  color: "white"
};
