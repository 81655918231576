import React from "react";
import "./film&sound.css";

export default function Preview(props) {
  return (
    <div className="preview-container">
      <div className="image-container">
        <img
          alt="forhåndsvisning"
          src={props.data.thumbnail}
          className="image"
        />
      </div>
      <h4 className="company">{props.data.company}</h4>
      <h1 className="title">{props.data.title}</h1>
    </div>
  );
}
