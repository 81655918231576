import React from "react";
import "./talks.css";

export default function TalkContainer() {
  return (
    <div className="main-talk">
      <div className="first-talk-container">
        <div>
          <h1 className="talk-title">Foredrag og samtaler</h1>
          <p className="talk-paragraph">
            Jeg holder foredrag om litteratur, biografi, filosofi,
            ideologikritikk. Jeg tar også på meg å lede litterære samtaler eller
            intervjuer. <br /> <br />
            Kontakt meg for å få et tilbud eller avtale en pris.
          </p>
          <br />
          <h3 className="talk-sub-title">Emner for foredrag:</h3>
          <ul className="talk-list">
            <li className="talk-list-item">Jeg, Arne Næss</li>
            <li className="talk-list-item">Kriminallitteraturens røtter</li>
            <li className="talk-list-item">Biografi og diktning</li>
            <li className="talk-list-item">
              Jordan Peterson og The Intellectual Dark Web
            </li>
          </ul>
        </div>
        <img src="svarthvit.JPG" alt="Ola Hegdal" width="100%" />
      </div>
    </div>
  );
}
