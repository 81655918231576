import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import FrontPage from "./components/mainpage/Frontpage";
import CartoonContainer from "./components/drawing/DrawingContainer";
import Post from "./components/film&sound/Post";
import TalkContainer from "./components/talks/TalkContainer";
import FilmContainer from "./components/film&sound/FilmContainer";
import CriticsContainer from "./components/critics/CriticsContainer";
import ContactContainer from "./components/contact/ContactContainer";

function App() {
  return (
    <div id="outer-container">
      <Router>
        <Header />
        <div style={{ minHeight: "100vh" }} id="page-wrap">
          <Switch>
            <Route exact path="/">
              <FrontPage />
            </Route>
            <Route path="/anmeldelser">
              <CriticsContainer />
            </Route>
            <Route path="/foredrag">
              <TalkContainer />
            </Route>
            <Route exact path="/film-og-lyd">
              <FilmContainer />
            </Route>
            <Route path="/film-og-lyd/:slug" component={Post} />
            <Route path="/tegneserier">
              <CartoonContainer />
            </Route>
            <Route path="/illustrasjoner">
              <CartoonContainer />
            </Route>
            <Route path="/kontakt">
              <ContactContainer />
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
