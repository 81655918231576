import React, { Component } from "react";
import Category from "./Category";
import DrawingCarousel from "./DrawingCarousel";
import "./drawing.css";
import "./../../index.css";
import { withRouter } from "react-router-dom";
import client from "../../client";
import groq from "groq";
const CATEGORY_QUERY = groq`*[_type == $type] {description, title, _id}`;
const DRAWING_QUERY = groq`*[_type == $type && title==$title] {description, title,_id, "drawings": *[_type == $type2 && references(^._id)] |order(order) {"image": image.asset->url}}`;

export default withRouter(
  class DrawingContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        categories: [],
        title: "",
        type: this.props.location.state.type,
        _id: null,
        description: null,
        drawings: [],
        loading: false
      };
      this.changeCategory = this.changeCategory.bind(this);
      this.fetchData = this.fetchData.bind(this);
      this.fetchCategories = this.fetchCategories.bind(this);
    }

    componentDidMount() {
      this.fetchCategories(this.state.type);
    }

    // fetch categories based on page: tegneserier/illustrasjoner
    fetchCategories(type) {
      client.fetch(CATEGORY_QUERY, { type }).then(res => {
        res = res.map(el => {
          return el.title;
        });
        this.setState(
          { ...this.state, type, categories: res, title: res[0] },
          () => {
            this.fetchData();
          }
        );
      });
    }

    // fetch drawings
    fetchData() {
      this.setState({ ...this.state, loading: true });
      const title = this.state.title;
      const type = this.state.type;
      const type2 = type === "cartoonCategory" ? "cartoon" : "illustration";
      client.fetch(DRAWING_QUERY, { type, type2, title }).then(res => {
        let data = res[0];
        const drawings = data.drawings;
        this.setState({ ...this.state, drawings, loading: false }, () => {});
      });
    }

    // change category
    changeCategory(title) {
      this.setState({ ...this.state, title }, () => {
        this.fetchData();
      });
    }

    // Fix
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.location.state.type !== nextProps.location.state.type) {
        this.fetchCategories(nextProps.location.state.type);
      }
    }

    render() {
      return (
        <div className="container">
          <div className="category">
            <Category
              categories={this.state.categories}
              activeCategory={this.state.title}
              setActiveCategory={this.changeCategory}
            />
          </div>
          <div className="main">
            {this.state.loading ? (
              <div />
            ) : (
              <div>
                <h1 className="cartoon-title">{this.state.title}</h1>
                <p className="cartoon-description">
                  {this.state.description && this.state.description}
                </p>
                {this.state.drawings.length > 0 && (
                  <DrawingCarousel data={this.state.drawings} />
                )}
              </div>
            )}
          </div>
        </div>
      );
    }
  }
);
