import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./drawing.css";

export default function DrawingCarousel(props) {
  // Change dummystate when new category is chosen.
  // In order to force update of thumbnails
  const [dummmyState, setDummyState] = useState(0);

  useEffect(() => {
    let newDummy = dummmyState;
    setDummyState(++newDummy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return (
    <Carousel
      showArrows={true}
      showStatus={false}
      showIndicators={false}
      dynamicHeight={true}
      infiniteLoop={true}
      useKeyboardArrows={true}
    >
      {props.data.map((el, index) => {
        return <img src={el.image} alt="Tegneserie" key={index} />;
      })}
    </Carousel>
  );
}
