import React from "react";
import "./film&sound.css";

export default function Post(props) {
  const { post } = props.location.state;
  return (
    <div>
      <div className="main-image-container">
        <img src={post.thumbnail} alt="header" className="main-image" />
      </div>
      <div className="main-content">
        <h1 className="post-title">{post.title}</h1>
        <p className="post-description">{post.description}</p>
        {post.video !== undefined && (
          <div className="resp-container">
            <iframe
              src={post.video}
              className="resp-iframe"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title={post.title}
            ></iframe>
          </div>
        )}
        {post.audio.map((el, index) => {
          return (
            <div key={index} className="audio">
              <h3
                style={{
                  marginLeft: 0,
                  marginBottom: "5px",
                  fontWeight: 350,
                  fontSize: "1.4em"
                }}
              >
                <i>{el.title}</i>
              </h3>
              <audio controls>
                <source src={el.audiofile} type="audio/mpeg" />
                Nettleseren støtter ikke audiofilen.
              </audio>
            </div>
          );
        })}
      </div>
    </div>
  );
}
