import React from "react";
import { NavLink } from "react-router-dom";
import "./frontpage.css";

export default function Frontpage() {
  return (
    <div
      className="frontpage"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="green-square"></div>
      <div className="main-section">
        <div className="main-header">
          <h1 className="front-name">Ola Hegdal</h1>
          <h1 className="occupation">– Tegner og forteller</h1>
        </div>
        <img
          className="ola-photography"
          src="./ola_fotografi.jpg"
          alt="Ola Hegdal"
        />
      </div>
      <div className="about-me">
        <div className="about-text">
          <h2 className="about-header">Om meg</h2>
          <br />
          <p className="about-paragraph">
            Av utdannelse er jeg litteraturviter, men jeg har ikke nøyd meg med
            en teoretisk tilnærming til historiefortellingen. Jeg lager manus og
            tegninger for de fleste formål: <br />
            <br />
            <b style={{ fontSize: "1.1em" }}>
              Anmeldelser - Tegneserier - Kortfilmer - Radiosketsjer
            </b>{" "}
            <br /> <br /> På disse sidene har jeg samlet et lite utvalg av
            tegninger og tekster jeg har laget.
          </p>
        </div>
        <div className="drawing-container">
          <img
            className="ola-drawing"
            src="./ola (1).jpg"
            alt="Tegning av Ola Hegdal"
          />
        </div>
      </div>
      <div className="contact-section">
        <NavLink
          to="/kontakt"
          style={{
            textDecoration: "none",
            color: "white",
            margin: "auto"
          }}
        >
          <h2 className="contact-me-button">KONTAKT</h2>{" "}
        </NavLink>
      </div>
    </div>
  );
}
