import React from "react";
import "./layout.css";

export default function Footer() {
  return (
    <div>
      <div className="author-footer">
        <h3 className="author">
          made by{" "}
          <a
            href="https://www.linkedin.com/in/mathilde-tillman-hegdal-40bb6514a/"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            <b>Mathilde Tillman</b> {" "}
          </a>
        </h3>
        <div />
      </div>
    </div>
  );
}
