import React from "react";
import "./drawing.css";

export default function Category(props) {
  const handleCategoryClick = e => {
    props.setActiveCategory(e.currentTarget.dataset.div_name);
  };

  return (
    <div>
      <ul className="category-list">
        {props.categories.map((el, index) => {
          return (
            <li
              key={index}
              className={
                el === props.activeCategory ? "chosen list-item" : "list-item"
              }
              data-div_name={el}
              onClick={handleCategoryClick}
            >
              {el}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
