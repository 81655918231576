import React, { useState } from "react";
import "./layout.css";
import { NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

export default function HamburgerMenu() {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleStateChange = state => {
    setMenuOpen(state.isOpen);
  };

  return (
    <Menu
      right
      isOpen={menuOpen}
      onStateChange={state => handleStateChange(state)}
    >
      <NavLink
        exact
        to="/"
        style={
          ({
            marginRight: "4vw"
          },
          defaultStyle)
        }
        activeStyle={activeStyle}
        onClick={closeMenu}
      >
        <h3 className="bm-item">Hjem</h3>
      </NavLink>
      <NavLink
        to="/anmeldelser"
        style={
          ({
            marginRight: "4vw"
          },
          defaultStyle)
        }
        activeStyle={activeStyle}
        onClick={closeMenu}
      >
        <h3 className="bm-item">Anmeldelser</h3>
      </NavLink>
      <NavLink
        to="/foredrag"
        style={defaultStyle}
        activeStyle={activeStyle}
        onClick={closeMenu}
      >
        <h3 className="bm-item">Foredrag</h3>
      </NavLink>
      <NavLink
        to="/film-og-lyd"
        style={defaultStyle}
        activeStyle={activeStyle}
        onClick={closeMenu}
      >
        <h3 className="bm-item">Film og lyd</h3>
      </NavLink>
      <NavLink
        to={{
          pathname: `/tegneserier`,
          state: { type: "cartoonCategory" }
        }}
        style={defaultStyle}
        activeStyle={activeStyle}
        onClick={closeMenu}
      >
        <h3 className="bm-item">Tegneserier</h3>
      </NavLink>
      <NavLink
        to={{
          pathname: `/illustrasjoner`,
          state: { type: "illustrationCategory" }
        }}
        style={defaultStyle}
        activeStyle={activeStyle}
        onClick={closeMenu}
      >
        <h3 className="bm-item">Illustrasjoner</h3>
      </NavLink>
      <NavLink
        to={{
          pathname: `/kontakt`
        }}
        style={defaultStyle}
        activeStyle={activeStyle}
        onClick={closeMenu}
      >
        <h3 className="bm-item">Kontakt</h3>
      </NavLink>
    </Menu>
  );
}

const defaultStyle = {
  textDecoration: "none",
  margin: "0 1.5vw",
  textTransform: "none",
  color: "white"
};

const activeStyle = {
  color: "#105670"
};
