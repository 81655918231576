import React, { useState, useEffect } from "react";

import "./film&sound.css";

export default function FadeInSection(props) {
  const [isVisible, setVisible] = useState(false);
  const domRef = React.useRef();

  useEffect(() => {
    // _isMounted prevents warning
    let _isMounted = true;
    const observer = new IntersectionObserver(entries => {
      if (_isMounted) {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      }
    });
    observer.observe(domRef.current);

    return function cleanUp() {
      _isMounted = false;
    };
  }, []);

  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}
