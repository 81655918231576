import React from "react";
import "./critics.css";

const CRITICS = [
  {
    url: "https://www.nrk.no/kultur/anmeldelse_-jo-nesbo-_kniv_-1.14577566",
    title: "Jo Nesbø: Kniv"
  },
  {
    url:
      "https://www.nrk.no/kultur/anmeldelse_-danehaeren-av-bjorn-andreas-bull-hansen-1.14757556",
    title: "Bjørn Andreas Bull-Hansen: Danehæren"
  },
  {
    url:
      "https://www.nrk.no/anmeldelser/anmeldelse_-_mannen-som-dode_-av-antti-tuomainen-1.14801415",
    title: "Antti Tuomainen: Mannen som døde"
  },
  {
    url:
      "https://www.nrk.no/kultur/bok/anmeldelse-av-_camille_-av-pierre-lemaitre-1.13356603",
    title: "Pierre Lemaitre: Camille"
  },
  {
    url:
      "https://www.aftenposten.no/meninger/debatt/i/BXR4v/vigdis-hjorth-og-sannheten-ola-a-hegdal",
    title: "Vigdis Hjorth: Arv og miljø"
  }
];

export default function CriticsContainer() {
  return (
    <div>
      <div className="main-talk">
        <h1 className="talk-title">Kritikk og anmeldelser</h1>
        <p className="talk-paragraph">
          Jeg har skrevet og snakket om bøker og litteratur i en årrekke, blant
          annet i Dagens Næringsliv, NRK P2, barnebokkritikk.no og Aftenposten.{" "}
          <br /> <br />
          Typiske emner har vært kriminallitteratur, bildebøker,
          dokumentar-bøker, etc. Mange av disse anmeldelsene er fortsatt
          tilgjengelig på nett. <br /> <br />
          Her er et lite utvalg kritikker, omtaler, anmeldelser og
          debattinnlegg.
        </p>
        <ul className="critic-list">
          {CRITICS.map((el, index) => {
            return (
              <li key={index} className="critic-list-item">
                <a href={el.url} target="_blank" rel="noopener noreferrer">
                  {el.title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
